/* global localStorage */

import { authService } from '../services';

const user = JSON.parse(localStorage.getItem('user'));
const initialState = user
  ? { loggedIn: true, user }
  : { loggedIn: false, user: null };

const authModule = {
  namespaced: true,
  state: {
    initialState
  },
  mutations: {
    loginSuccess  (state, user) {
      state.loggedIn = true;
      state.user = user;
    },
    loginFailure  (state) {
      state.loggedIn = false;
      state.user = null;
    }
  },
  actions: {
    async loginMember ({ commit }, data) {
      try {
        const result = await authService.login(data);

        if (result.data !== null) {
          commit('loginSuccess', result.data);
          return result;
        }
      } catch (error) {
        commit('loginFailure');
        if (error.response) {
          return error.response;
        }
      }
    },
    async register ({ commit }, data) {
      try {
        const result = await authService.register(data);

        if (result.data !== null) {
          commit('loginSuccess', result.data);
          return result;
        }
      } catch (error) {
        commit('loginFailure');
        if (error.response) {
          return error.response;
        }
      }
    },
    async registerByLine ({ commit }, data) {
      try {
        const result = await authService.registerByLine(data);

        if (result.data !== null) {
          commit('loginSuccess', result.data);
          return result;
        }
      } catch (error) {
        commit('loginFailure');
        if (error.response) {
          return error.response;
        }
      }
    },
    async loginByCsite ({ commit }, data) {
      try {
        const result = await authService.loginByCsite(data);

        if (result.data !== null) {
          commit('loginSuccess', result.data);
          return result;
        }
      } catch (error) {
        commit('loginFailure');
        if (error.response) {
          return error.response;
        }
      }
    },
    async loginByLine ({ commit }, data) {
      try {
        const result = await authService.loginByLine(data);

        if (result.data !== null) {
          commit('loginSuccess', result.data);
          return result;
        }
      } catch (error) {
        commit('loginFailure');
        if (error.response) {
          return error.response;
        }
      }
    }
  },
  getters: {
    getLoginState (state) {
      return state.loggedIn;
    },
    getUser (state) {
      return state.user;
    }
  }
};

export default authModule;

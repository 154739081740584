export default {
  "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["hello i18n !!"])},
  "username": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Username"])},
  "password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Password"])},
  "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email"])},
  "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["First name"])},
  "surname": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Surname"])},
  "displayName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Display name"])},
  "telephone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Telophone no."])},
  "confirmPassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Comfirm password"])},
  "referenceID": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reference ID"])},
  "sex": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sex"])},
  "male": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Male"])},
  "female": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Female"])},
  "login": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Login"])},
  "register": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Register"])},
  "reset": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reset"])},
  "receiveNoti": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Receive notification"])},
  "please_enter_email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please enter email."])},
  "please_enter_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please enter name."])},
  "please_enter_surname": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please enter surname."])},
  "please_enter_displayname": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please enter displayname."])},
  "please_enter_password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please enter password."])},
  "Please_enter_the_same_password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please enter the same password."])},
  "email_already_exists": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email already exists."])},
  "must_be_a_valid_email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email must be a valid email"])},
  "must_be_less_than_128": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Length must be less than or equal to 128 characters long."])},
  "must_be_least_6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Length must be at least 6 characters long."])},
  "please_enter_email_or_password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please enter email or password."])},
  "incorrect_email_or_password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Incorrect email or password"])},
  "notification_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Notification"])},
  "ok": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["OK"])},
  "incorrect_username_or_password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Username or password is incorrect."])},
  "login_success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Login Success"])},
  "please_enter_username_password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please enter username or password."])},
  "please_register": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please register a member."])},
  "register_not_success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Register is not success."])}
}
export default {
  "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["สวัสดี99"])},
  "username": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ผู้ใช้งาน"])},
  "password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["รหัสผ่าน"])},
  "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["อีเมล"])},
  "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ชื่อ"])},
  "surname": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["นามสกุล"])},
  "displayName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ชื่อผู้ใช้งาน"])},
  "telephone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["หมายเลขโทรศัพท์"])},
  "confirmPassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ยืนยันรหัสผ่าน"])},
  "referenceID": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["รหัสอ้างอิงสมาชิก"])},
  "sex": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["เพศ"])},
  "male": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["เพศชาย"])},
  "female": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["เพศหญิง"])},
  "login": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["เข้าสู่ระบบ"])},
  "register": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["สมัครสมาชิก"])},
  "reset": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ล้างค่า"])},
  "receiveNoti": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["รับการแจ้งเตือน"])},
  "please_enter_email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["กรุณากรอกอีเมล"])},
  "please_enter_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["กรุณากรอกชื่อ"])},
  "please_enter_surname": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["กรุณากรอกนามสกุล"])},
  "please_enter_displayname": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["กรุณากรอกชื่อผู้ใช้"])},
  "please_enter_password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["กรุณากรอกรหัสผ่าน"])},
  "Please_enter_the_same_password.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["กรุณากรอกรหัสผ่านให้ตรงกัน"])},
  "email_already_exists": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["มีอีเมลนี้ในระบบแล้ว"])},
  "must_be_a_valid_email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["รูปแบบอีเมลไม่ถูกต้อง"])},
  "must_be_less_than_128": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ความยาวจะต้องน้อยกว่าหรือเท่ากับ 128 ตัวอักษร"])},
  "must_be_least_6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ความยาวจะต้องมากกว่าหรือเท่ากับ 6 ตัวอักษร"])},
  "please_enter_email_or_password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["กรุณากรอกอีเมลหรือรหัสผ่าน"])},
  "incorrect_email_or_password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["อีเมลหรือรหัสผ่านไม่ถูกต้อง"])},
  "notification_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["แจ้งเตือน"])},
  "ok": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ตกลง"])},
  "incorrect_username_or_password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ชื่อผู้ใช้หรือรหัสผ่านไม่ถูกต้อง"])},
  "login_success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["เข้าสู่ระบบสำเร็จ"])},
  "please_enter_username_password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["กรุณากรอก username หรือ password"])},
  "please_register": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["กรุณาสมัครสมาชิก"])},
  "register_not_success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["สมัครสมาชิกไม่สำเร็จ"])}
}